<template>
  <publicImport :importTypeList="importTypeList"></publicImport>
</template>

<script>
import { alias2f085dd487c1465383484787b29fef2c } from '@/customFolder/customVueAlias.js';

import publicImport from "@/components/common/publicImport";

export default {
  name: "importTask",
  data() {
    return {
      importTypeList: [
        {value: 'productImport', name: alias2f085dd487c1465383484787b29fef2c.t('key1000670')},
        {value: 'inventoryImport', name: alias2f085dd487c1465383484787b29fef2c.t('key1000698')},
        {value: 'productGoodsSuggestPriceImport', name: alias2f085dd487c1465383484787b29fef2c.t('key1000720')},
        {value: 'productGoodsStatusImport', name: alias2f085dd487c1465383484787b29fef2c.t('key1005501')},
      ]
    };
  },
  components: {
    publicImport
  }
};
</script>
<style scoped>

</style>
